<template>
  <div v-if="user">
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6 d-flex align-items-center">
        <h4 class="mb-1 mt-0">Users</h4>
        <button class="btn btn-primary rounded-circle ml-3 d-flex align-items-center justify-content-center"
                style="width: 24px; height: 24px; padding: 0;" @click="openAddModal">
          <i class="uil uil-plus" style="font-size: 12px; line-height: 1;"></i>
        </button>

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <td>First Name</td>
                  <td>Last Name</td>
                  <td>Email</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
              <tr v-for="u in user.created_team_users" :key="u.id">
                <td>{{ u.first_name }}</td>
                <td>{{ u.last_name }}</td>
                <td>{{ u.email }}</td>
                <td class="text-right">
                  <button class="btn btn-sm btn-dark-blue" @click="editTeamUser(u)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-2" @click="askToDeleteTeamUser(u)">Delete</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete User">
      <p v-if="selectedUser"
      >Are you sure you want to delete user "{{
          selectedUser.first_name + ' ' + selectedUser.last_name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteUser">
          <b-spinner v-if="loadingRemove" small />
          <span>Delete</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="addUser" :hide-footer="true" title="Team User" @close="resetNewUser">
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="newUserForm"
      >
        <form
            class="new-user-form"
            @submit.prevent="handleSubmit(addNewUser)"
        >
          <div class="row">
            <div class="col-12">
              <TextInput
                  v-model="newUser.first_name"
                  name="first_name"
                  rules="required"
                  placeholder="First Name"
              />
            </div>
            <div class="col-12">
              <TextInput
                  v-model="newUser.last_name"
                  name="last_name"
                  rules="required"
                  placeholder="Last Name"
              />
            </div>
            <div class="col-12">
              <TextInput
                  v-model="newUser.email"
                  name="email"
                  rules="required|email"
                  placeholder="Email"
              />
            </div>
            <div class="col-12">
              <label for="teams">Account Access</label>
              <button v-if="user.role !== 'Business'" type="button" class="btn btn-link" @click="checkAllTeams">Check All</button>
              <button v-if="user.role !== 'Business'" type="button" class="btn btn-link" @click="uncheckAllTeams">Uncheck All</button>
              <!-- Dropdown with checkboxes for teams -->
              <b-form-group id="teams">
                <div class="scrollable-checkbox-group">
                  <b-form-checkbox-group
                      v-model="newUser.selectedTeams"
                      :options="teamOptions"
                      name="teams"
                      stacked
                      class="small-checkboxes"
                  />
                </div>
              </b-form-group>
            </div>
          </div>
          <b-form-group class="text-right">
            <b-button
                variant="primary"
                class="btn-dark-blue"
                :disabled="loadingUser || (invalid && touched)"
                type="submit"
            >
              <b-spinner v-if="loadingUser" small />
              <span v-else>Submit</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      selectedUser:null,
      loadingRemove:false,
      showDeleteModal:false,
      addUser:false,
      loadingUser: false,
      user: {
        agency: {},
      },
      newUser:{
        first_name:'',
        last_name:'',
        email:'',
        role:'',
        agency:null,
        selectedTeams: []
      },
    }
  },
  computed: {
    teamOptions() {
      return this.user.teams.map(team => ({
        value: team.id,
        text: team.name
      }))
    },
    selectedTeam(){
      return this.user ? this.user.teams?.find(t => this.user?.role === 'Agency' ? t?.team_users.filter(t2 => t2.created_by === this.user?.id) : t.owner_id === this.user?.id) : null;
    },
    selectedTeamUserCount() {
      return this.selectedTeam ? this.selectedTeam.team_users.length : 0;
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.newUser.role = this.user.role
    this.newUser.agency = this.user.team_agency;
    if(this.teamOptions.length === 1 && this.user.role === 'Business'){
      this.newUser.selectedTeams = [this.teamOptions[0].value]
    }
  },
  methods: {
    openAddModal(){
      const teamLimit = this.user.business?.limits?.team_user_limit ?? 50;
      if(this.selectedTeamUserCount >= teamLimit){
        Vue.$toast.open({
          message: "Sorry, but you have reached your team limit.",
          type: 'error',
          duration: 5000,
        })
      }else {
        this.addUser = true;
      }
    },
    deleteUser() {
      this.loadingRemove = true;
      this.$store
          .dispatch('user/removeTeamUser', {
            userId: this.selectedUser.id,
            teamId: this.selectedTeam.id
          })
          .then((res) => {
            this.user.teams = res.data.user.teams;
            this.showDeleteModal = false
            this.selectedUser = null
            this.loadingRemove = false;
          })
          .catch(() => {
            this.loadingRemove = false;
          });
    },
    askToDeleteTeamUser(user){
      this.selectedUser = user;
      this.showDeleteModal = true;
    },
    editTeamUser(teamUser){
      this.newUser.first_name = teamUser.first_name;
      this.newUser.last_name = teamUser.last_name;
      this.newUser.email = teamUser.email;
      this.newUser.selectedTeams = this.user.teams.filter(team => team.team_users.some(user => user.id === teamUser.id)).map(team => team.id)
      this.addUser = true;
    },
    checkAllTeams() {
      // Select all teams in the checkbox group
      this.newUser.selectedTeams = this.user.teams.map(team => team.id);
    },
    uncheckAllTeams() {
      // Unselect all teams in the checkbox group
      this.newUser.selectedTeams = [];
    },
    resetNewUser() {
      this.newUser = {
        first_name: '',
        last_name: '',
        email: '',
        agency: this.user.team_agency,
        selectedTeams: []
      };
    },
    addNewUser() {
      this.loadingUser = true
      this.$store
        .dispatch('user/addTeamUser', this.newUser)
        .then((res) => {
          this.user.teams = res.data.user.teams;
          this.user.created_team_users = res.data.user.created_team_users;
          this.loadingUser = false
          this.addUser = false;
          this.resetNewUser();
        })
        .catch((err) => {
          console.log(err);
          this.loadingUser = false
        })
    },
  },
}
</script>
<style>
.small-checkboxes .custom-control{
  padding-left: 1.25rem;
  margin-bottom:5px;
}
.small-checkboxes .custom-control-label::before,
.small-checkboxes .custom-control-label::after{
  width: 1.25rem; /* Adjust width */
  height: 1.25rem; /* Adjust height */
  top: 0; /* Adjust vertical position */
  left: -1.25rem; /* Adjust horizontal position */
  border-radius: 0.25rem; /* Optional: Adjust border radius for rounded checkboxes */
}

/* Adjust the custom checkbox input size (optional for more control) */
.small-checkboxes .custom-control-input {
  width: 1.25rem;  /* Adjust width */
  height: 1.25rem; /* Adjust height */
}

/* Adjust the font size of the label */
.small-checkboxes .custom-control-label {
  font-size: 0.875rem; /* Make the label smaller */
  padding-left: 5px;
}

/* Optional: Adjust the margin and padding to make it look more compact */
.small-checkboxes {
  margin-bottom: 0.5rem; /* Adjust as needed */
}
.scrollable-checkbox-group {
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional: Adds a border */
  padding: 5px;
}
</style>
